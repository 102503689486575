

export const getAccounts = async (accessToken: string, refreshToken: string) => {
    
    const resp = await fetchApi('https://api.carrierify.com/account', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    }, accessToken, refreshToken)
   const data = await resp.json();;
   return data;
}

export const getTemplates = async (accessToken: string, refreshToken: string) => {
    const resp = await fetchApi('https://api.carrierify.com/templates', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }}, accessToken, refreshToken);
    const data = await resp.json();;
   return data; 
}

export const sendEmailV3 = async (toEmail: string, subject: string, data: string, fromEmail: string, accessToken: string, refreshToken: string) => {
    
    const response = await fetchApi(`https://api.carrierify.com/email/send/v3`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
           to: toEmail, 
           subject: subject,
           fromEmail: fromEmail,
           html: data})
      }, accessToken, refreshToken);
      return getEmaiilSendStatus(response);
}





export const fetchApi = async (url: string, options: any, accessToken: string, refreshToken: string) => {
   
    const response = await fetch(url, options);
   
    if(response.status === 401 && options.headers.Authorization){
       const token: any = await refreshTokenApi(accessToken, refreshToken);
      
       options.headers.Authorization = `Bearer ${token.accessToken}`;
       
       const response2 = await fetch(url, options);
       
       return response2;
    }
    return response;
}

export const refreshTokenApi = async (accessToken: string, refreshToken: string) => {
    const data = await new Promise((resolve, reject) => {
      fetch(`https://api.carrierify.com/auth/refresh?refreshToken=${refreshToken}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          resolve(data)
        })
        .catch((err) => {
         
          reject(err)
        })
    });
    return data;
}

export const getFuelPriceApi = async(lat: any, lon: any) => {
    const data = await fetch(`https://ref.loadconnect.io/fuel?lat=${lat}&lon=${lon}`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
        }
    })
    const resp = await data.json();
    return resp;
}

export const getTollApi =async (polyline:string) => {
    const data = await fetch('https://ref.loadconnect.io/toll', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({polyline})
    })
    const resp = await data.json();
    return resp;
}

export const updatePricePrecision =async (fuelPrice: any) => {
    try {

        fuelPrice.gasoline = Number(fuelPrice.gasoline).toFixed(2);
    fuelPrice.diesel = Number(fuelPrice.diesel).toFixed(2);
    fuelPrice.lpg = Number(fuelPrice.lpg).toFixed(2);

    } catch (e) {

    }

    return fuelPrice;
    
}
const getEmaiilSendStatus = (response: any): EmailSendStatus => {
    switch(response.status){
        case 201:
            return EmailSendStatus.EMAIL_SENT;
        case 401:
            return EmailSendStatus.UNAUTHORIZED;
        case 409:
            return EmailSendStatus.VERIFIFICATION_REQUIRED;
        case 501:
            return EmailSendStatus.RECHARGE;
        default: 
            return EmailSendStatus.ERROR;
    }
}

export enum EmailSendStatus {
    NONE = "NONE",
    VERIFIFICATION_REQUIRED = "VERIFICATION_REQUIRED",
    RECHARGE = "RECHARGE",
    EMAIL_SENT = "EMAIL_SENT",
    EMAIL_NOT_SENT = "EMAIL_NOT_SENT",
    UNAUTHORIZED = "UNAUTHORIZED",
    ERROR = "ERROR",
}

export const reformatText = (text: string, data: any) => {
    text = text.replaceAll('{{origin}}', data.origin);
    text = text.replaceAll('{{dest}}', data.destination);
    text = text.replaceAll('{{avail}}', data.pickupDate);
    text = text.replaceAll('{{truck}}', data.truckType);
    text = text.replaceAll('{{email}}', data.email);
    text = text.replaceAll('{{company}}', data.company);
    text = text.replaceAll('{{rate}}', data.rate);
    text = text.replaceAll('{{ref}}', '-');
    text = text.replaceAll('{{trip}}', data.distance);
    text = text.replaceAll('{{do}}', '-');
    return text;
}