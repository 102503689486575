import { Input } from "@nextui-org/input";
import { useCallback, useEffect, useState } from "react";

export const AdvancedCalculator = ({ loadinfo, tripInfo }: any) => {
  const [miles, setMiles] = useState(0);
  const [rpm, setRpm] = useState(0);
  const [mpg, setMpg] = useState(6.8);
  const [diesel, setDiesel] = useState(0);
  const [toll, setToll] = useState(0);
  const [rate, setRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  useEffect(() => {
    if (tripInfo?.fuelPrice?.diesel) {
      const die = Number(tripInfo.fuelPrice.diesel);
      setDiesel(die);
      setFuelCost(Number(((miles / mpg) * die).toFixed(2)));
    }
    if (tripInfo?.tollCost) {
      setToll(Number(tripInfo?.tollCost));
    }
  }, [tripInfo]);

  useEffect(() => {
    let milesIn = 0;
    if (loadinfo.distance) {
      milesIn = Number(loadinfo.distance.replace(" mi", "").replace(",", ""));
      setMilesIn(milesIn);
    }
    let rateIn = 0;
    if (loadinfo.rate && loadinfo.rate !== "–") {
      console.log(loadinfo.rate);
      rateIn = Number(loadinfo.rate.replace("$", "").replace(",", ""));
      setRateIn(rateIn);
    } else {
      setRateIn(0);
    }

    setMilesIn(milesIn);
  }, [loadinfo]);

  const setMilesIn = (val: number) => {
    setMiles(val);

    setRpm(Number((rate / val).toFixed(2)));
    setFuelCost(Number(((val / mpg) * diesel).toFixed(2)));
  };

  const setRpmIn = (val: number) => {
    setRpm(val);
    setRate(Number((val * miles).toFixed(2)));
    setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
  };

  const setMpgIn = (val: number) => {
    setMpg(val);
    setFuelCost(Number(((miles / val) * diesel).toFixed(2)));
  };

  const setRateIn = (val: number) => {
    setRate(val);

    setRpm(Number((val / miles).toFixed(2)));
    setFuelCost(Number(((miles / mpg) * diesel).toFixed(2)));
  };

  const setFuelCostTollsIn = (val: number) => {
    setFuelCost(val);
  };

  return (
    <div className="">
      <div className="col-span-4 flex flex-col gap-2">
        <div className="col-span-4">
          <div className="grid grid-cols-3 gap-x-1 gap-y-2">
            <Input
              size="sm"
              variant="bordered"
              type="number"
              name="total"
              id="total"
              label="MILES"
              value={miles.toString()}
              onValueChange={(e) => {
                setMilesIn(Number(e));
              }}
            />
            <Input
              size="sm"
              variant="bordered"
              type="number"
              name="rpm"
              id="rpm"
              label="RPM"
              step={0.5}
              value={rpm.toString()}
              onValueChange={(e) => {
                setRpmIn(Number(e));
              }}
            />
            <Input
              size="sm"
              variant="bordered"
              type="number"
              name="mpg"
              id="mpg"
              label="MPG"
              step={0.5}
              value={mpg.toString()}
              onValueChange={(e) => {
                setMpgIn(Number(e));
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-0.5 grid  grid-cols-3 bg-sky-500 rounded-lg flex flex-col gap-2 px-2 py-2">
        <Input
          type="number"
          size="sm"
          label="Rate"
          value={rate.toString()}
          onValueChange={(e) => {
            setRateIn(Number(e));
          }}
          startContent={<span>$</span>}
        />
        <Input
          type="number"
          size="sm"
          label="Fuel Cost"
          value={fuelCost.toString()}
          onValueChange={(e) => {
            setFuelCostTollsIn(Number(e));
          }}
          startContent={<span>$</span>}
        />
        <Input disabled type="number" size="sm" label="Profit" value={(rate - fuelCost - toll).toFixed(2)} startContent={<span>$</span>} />
      </div>
    </div>
  );
};
