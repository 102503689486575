import { Card, CardBody, CardHeader } from "@nextui-org/card";
import Map from "./Map";
import LoadDetail from "../components/LoadDetail";
import { Calculator } from "../components/Calculator";
import { Badge, Button, Popover, PopoverContent, PopoverTrigger, Tab, Tabs } from "@nextui-org/react";
import TripDetail from "../components/TripDetails";
import { useState } from "react";

const LoadInfo = ({ loadInfo, setLoadInfo }: any) => {
  const [tripInfo, setTripInfo] = useState<any>({});
  return (
    <div className="flex flex-col gap-2">
      <Card>
        <Map loadinfo={loadInfo} setLoadInfo={setLoadInfo} tripInfo={tripInfo} setTripInfo={setTripInfo}></Map>
      </Card>
      <Card>
        <CardBody>
          <Tabs color="primary" radius="lg" aria-label="Tabs colors">
            <Tab key="trip" title="Trip Details">
              <TripDetail loadInfo={loadInfo} tripInfo={tripInfo}></TripDetail>
            </Tab>
            <Tab key="load" title="Load Info">
              <LoadDetail loadInfo={loadInfo}></LoadDetail>
            </Tab>
          </Tabs>
        </CardBody>
      </Card>

      <div>
        <Card>
          <CardBody>
            <Popover placement="right" color="primary">
              <PopoverTrigger>
                <Badge content="?" color="primary" size="md">
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="text-lg font-semibold text-gray-700 w-full">Calculator</h2>
                  </div>
                </Badge>
              </PopoverTrigger>
              <PopoverContent>
                <div className="px-1 py-2">
                  <div className="text-small font-bold">Formula of Calculator</div>
                  <div className="text-tiny">
                    <span className="text-semibold">Rate = </span> Miles * RPM
                  </div>
                  <div className="text-tiny">
                    <span className="text-semibold">Fuel Cost = </span> (Miles / MPG) * Diesel Price
                  </div>
                  <div className="text-tiny">
                    <span className="text-semibold">Profit = </span> Rate - Fuel Cost - Tolls
                  </div>
                </div>
              </PopoverContent>
            </Popover>

            <Calculator loadinfo={loadInfo} tripInfo={tripInfo} />
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default LoadInfo;
