import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKEditorWrapper = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?: any;
}) => {

  return (
    <CKEditor
      editor={ClassicEditor}
      data={value || ""}
      onReady={(editor) => {
        // console.log("CKEditor5 React Component is ready to use!", editor);
        editor.editing.view.change((writer) => {
          const rootElement = editor.editing.view.document.getRoot();
          if (rootElement) {
            return writer.setStyle("height", "300px", rootElement);
          }
        });
        // editor.editing.view.document.on('keydown', (evt, data) => {
        //   onChange && onChange(data);
        // })
      }}
      onBlur={(event, editor) => {
        const data = editor.getData();
        onChange && onChange(data);
      }}
      // onChange={(event, editor) => {
      //   const data = editor.getData();
      //   onChange && onChange(data);
      // }}
    />
  );
};

export default CKEditorWrapper;
