import { NextUIProvider } from "@nextui-org/react";
import ModuleView from "./modules";
import posthog from "posthog-js";
function App() {
  //posthog.init("phc_yNSsw3Gfcb5lEp06hDbQB4bbQBMX1ozIbJEW7fVqoB0", { api_host: "https://app.posthog.com" });
  return (
    <NextUIProvider>
      <ModuleView />
    </NextUIProvider>
  );
}

export default App;
