import { Card, CardBody } from "@nextui-org/card";
import { Input } from "@nextui-org/input";

import { useEffect, useState } from "react";
import { EmailSendStatus, getAccounts, getTemplates, reformatText, sendEmailV3 } from "./server";
import CKEditor from "./CKEditor";
import { Button, Select, SelectItem } from "@nextui-org/react";
import { CheckCircleIcon, PaperAirplaneIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Account, Template } from "../types";

const defaultTemplate = {
  id: -1,
  name: "",
  subject: "",
  template: "",
  cc: "",
  labels: [],
};

const Editor = ({ loadinfo }: any) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [currentTemplate, setCurrentTemplate] = useState<Template>(defaultTemplate);
  const [activeAccount, setActiveAccount] = useState<Account | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState(EmailSendStatus.NONE);
  const [toEmail, setToEmail] = useState(loadinfo.email);

  useEffect(() => {
    if (loadinfo.accessToken && loadinfo.accessToken !== "") {
      const acccesToken = loadinfo.accessToken;
      const refreshToken = loadinfo.refreshToken;
      getAccounts(acccesToken, refreshToken).then((res: Account[]): void => {
        setAccounts(res);
        setStatus(EmailSendStatus.NONE);
        res.forEach((item) => {
          if (item.email === loadinfo.activeAccount.email) {
            setActiveAccount({ ...item });
          }
        });
        if (activeAccount === null && res.length == 1) setActiveAccount(res[0]);
      });
      getTemplates(acccesToken, refreshToken).then((res: Template[]): void => {
        setTemplates(res);
        res.forEach((item) => {
          if (item.id.toString() === loadinfo.activeTemplateId) {
            setCurrentTemplate({ ...item });
          }
        });
      });
      setToEmail(loadinfo.email);
    }
  }, [loadinfo]);

  const onChangeTemplate = (e: any) => {
    const id: Number = e.target.value;
    const template: Template | undefined = templates.find((item) => item.id === Number(id));
    if (!template) return;
    setCurrentTemplate(template);
  };

  const onChangeSubject = (value: string) => {
    setCurrentTemplate({ ...currentTemplate, subject: reformatText(value, loadinfo) });
  };

  const onChangeCKEditor = (data: string) => {
    setCurrentTemplate({ ...currentTemplate, template: reformatText(data, loadinfo) });
  };

  const onChangeAccount = (e: any) => {
    const id: Number = e.target.value;
    const account: Account | undefined = accounts.find((item) => item.id === Number(id));
    setActiveAccount(account || null);
  };

  const closeModal = () => {
    window.parent.postMessage("closeldcmodal", "*");
  };

  const sendEmail = () => {
    if (status !== EmailSendStatus.NONE) {
      closeModal();
      return;
    }
    setIsSending(true);
    sendEmailV3(
      toEmail,
      reformatText(currentTemplate.subject, loadinfo),
      reformatText(currentTemplate.template, loadinfo),
      activeAccount ? activeAccount.email : "",
      loadinfo.accessToken,
      loadinfo.refreshToken
    )
      .then((res) => {
        if (res) {
          setIsSending(false);
          setStatus(res);
        }
      })
      .catch((err) => {
        setIsSending(false);
        setStatus(EmailSendStatus.ERROR);
      });
  };

  return (
    <Card>
      <CardBody className="flex flex-col gap-4">
        <Select
          aria-label="from"
          variant="bordered"
          label="From"
          onChange={onChangeAccount}
          selectedKeys={activeAccount ? [activeAccount.id.toString()] : []}
        >
          {accounts.map((item) => (
            <SelectItem key={item.id.toString()}>{item.email}</SelectItem>
          ))}
        </Select>
        {activeAccount && activeAccount.verified == false && (
          <div className="text-red-500 text-sm font-bold">
            This account is not connected, to connect go to this&nbsp;
            <a className="text-blue-500" href="https://app.loadconnect.io/email" target="_blank">
              link
            </a>
          </div>
        )}
        <div className="grid grid-cols-2 gap-2">
          <Input
            aria-label="email to"
            label="Email to"
            variant="bordered"
            type="email"
            value={toEmail}
            onValueChange={(e) => {
              setToEmail(e);
            }}
          />
          <Select
            aria-label="template"
            variant="bordered"
            label="Select Template"
            onChange={onChangeTemplate}
            selectedKeys={currentTemplate.id === -1 ? [] : [currentTemplate.id.toString()]}
          >
            {templates.map((item) => (
              <SelectItem key={item.id.toString()}>{item.name}</SelectItem>
            ))}
          </Select>
        </div>
        <Input
          aria-label="subject"
          label="Subject"
          variant="bordered"
          value={reformatText(currentTemplate.subject, loadinfo)}
          onValueChange={onChangeSubject}
        />
        <span>Body</span>
        <CKEditor value={reformatText(currentTemplate.template, loadinfo)} onChange={onChangeCKEditor} />
        {activeAccount && activeAccount.verified && (
          <div className="flex justify-end gap-4">
            <Button aria-label="close" color="danger" onClick={closeModal} endContent={<CheckCircleIcon className="w-5 h-5" />}>
              Close
            </Button>
            <Button
              isLoading={isSending}
              aria-label="send"
              color="primary"
              onClick={sendEmail}
              endContent={
                status === EmailSendStatus.EMAIL_SENT ? <XCircleIcon className="w-5 h-5" /> : <CheckCircleIcon className="w-5 h-5" />
              }
            >
              {status === EmailSendStatus.NONE
                ? "Send"
                : status === EmailSendStatus.EMAIL_SENT
                ? "Email Successfully Sent"
                : status === EmailSendStatus.ERROR
                ? "Error"
                : status === EmailSendStatus.UNAUTHORIZED
                ? "Unauthorized"
                : status === EmailSendStatus.VERIFIFICATION_REQUIRED
                ? "Verification Required"
                : status === EmailSendStatus.RECHARGE
                ? "Recharge"
                : "Send"}
            </Button>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default Editor;
