import { useState } from "react";
import { LoadDetail } from "../types";
import Editor from "./Editor";
import LoadInfo from "./LoadInfo";

const ModuleView = () => {
  // const sample: LoadDetail = {
  //   origin: "",
  //   destination: "",
  //   pickupDate: "",
  //   truckType: "",
  //   duration: "",
  //   distance: "",
  //   rate: "",
  //   accessToken: "",
  //   refreshToken: "",
  //   company: "",
  // };

  const [load, setLoad] = useState<Partial<LoadDetail>>({});

  function receiveMessage(event: any) {
    if (event.data && event.data.origin && event.data.dest) {
      setLoad({
        ...load,
        origin: event.data.origin,
        destination: event.data.dest,
        company: event.data.company,
        rate: event.data.rate,
        pickupDate: event.data.avail,
        truckType: event.data.truck,
        accessToken: event.data.acccesToken,
        refreshToken: event.data.refreshToken,
        activeAccount: event.data.activeAccount,
        activeTemplateId: event.data.activeTemplateId,
        email: event.data.email,
      });
    }
  }

  window.addEventListener("message", receiveMessage, false);

  return (
    <>
      <h2 className="text-2xl font-medium pl-5 mt-3">
        Load from <span className="font-bold">{load.origin}</span> to <span className="font-bold">{load.destination}</span>
      </h2>
      <hr className="my-2" />
      <div className="grid grid-cols-11 gap-2 mx-2">
        <div className="col-span-4">
          <LoadInfo loadInfo={load} setLoadInfo={setLoad}></LoadInfo>
        </div>
        <div className="col-span-7">
          <Editor loadinfo={load}></Editor>
        </div>
      </div>
    </>
  );
};

export default ModuleView;
