import { CalendarIcon, TruckIcon, ClockIcon, ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import { DocumentDuplicateIcon, RocketLaunchIcon } from "@heroicons/react/24/solid";
import { CircularProgress } from "@nextui-org/react";

const TripDetail = ({ loadInfo, tripInfo }: any) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <ArrowUturnRightIcon className="w-5 h-5 text-gray-500"></ArrowUturnRightIcon>
            <div className="text-sm font-sm text-gray-500">Distance</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.distance || "- miles"}</div>
        </div>

        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <ClockIcon className="w-5 h-5 text-gray-500"></ClockIcon>
            <div className="text-sm font-sm text-gray-500">Duration</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.duration || "- hours"}</div>
        </div>
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <DocumentDuplicateIcon className="w-5 h-5 text-gray-500"></DocumentDuplicateIcon>
            <div className="text-sm font-sm text-gray-500">Tolls</div>
          </div>
          <div className="text-sm font-bold px-1">
            {tripInfo?.tollCost ? `$` + tripInfo?.tollCost : <CircularProgress size="sm" aria-label="Loading..." />}
          </div>
        </div>
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <RocketLaunchIcon className="w-5 h-5 text-gray-500"></RocketLaunchIcon>
            <div className="text-sm font-sm text-gray-500">Diesel Price gl</div>
          </div>
          <div className="text-sm font-bold px-1">
            {tripInfo?.fuelPrice?.diesel ? `$` + tripInfo?.fuelPrice?.diesel : <CircularProgress size="sm" aria-label="Loading..." />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripDetail;
