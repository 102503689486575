import { CSSProperties, useEffect, useRef } from "react";
import { getFuelPriceApi, getTollApi, updatePricePrecision } from "./server";

const mapContainerStyle: CSSProperties = {
  position: "relative",
  height: "200px",
  zIndex: "1",
};
const center = {
  lat: 37.839333,
  lng: -84.27002,
};

const Map = ({ loadinfo, setLoadInfo, tripInfo, setTripInfo }: any) => {
  const mapRef = useRef<any>(null);
  const directionsService = useRef<any>(null);
  const directionsRenderer = useRef<any>(null);

  const initializeMap = () => {
    const mapState = {
      center,
      zoom: 5,
      options: {
        gestureHandling: "greedy",
        disableDefaultUI: true,
      },
    };
    const mapContainer = document.getElementById("map");
    if (!mapContainer) return;
    const map = new google.maps.Map(mapContainer, mapState);

    mapRef.current = map;
    directionsService.current = new google.maps.DirectionsService();
    directionsRenderer.current = new google.maps.DirectionsRenderer();
  };

  useEffect(() => {
    initializeMap();
  }, []);

  const fuelPriceGet = async (startLocation: any, duration: string, distance: string, tollCost: number) => {
    const lat = startLocation.lat();
    const lon = startLocation.lng();
    getFuelPriceApi(lat, lon).then((fuelPrice) => {
      updatePricePrecision(fuelPrice);
      setTripInfo({ ...tripInfo, fuelPrice, tollCost: tollCost.toString() });
    });
  };

  const tollGet = async (polyline: string, duration: string, distance: string): Promise<number> => {
    try {
      const data = await getTollApi(polyline);

      if (!data.costs.minimumTollCost) {
        setTripInfo({ ...tripInfo, tollCost: "0" });
        return 0;
      } else {
        setTripInfo({ ...tripInfo, tollCost: data.costs.minimumTollCost });
      }

      data.tolls.forEach((toll: any) => {
        new google.maps.Marker({
          position: { lat: toll.lat, lng: toll.lng },
          map: mapRef.current,
          icon: {
            url: "https://w7.pngwing.com/pngs/563/791/png-transparent-dollar-sign-illustration-united-states-dollar-icon-design-icon-dollar-sign-text-logo-number.png",
            scaledSize: new google.maps.Size(20, 20),
          },
        });
      });
      return data.costs.minimumTollCost;
    } catch (e) {
      setTripInfo({ ...tripInfo, tollCost: "0" });
      return 0;
    }
  };

  useEffect(() => {
    if (loadinfo.origin && loadinfo.origin !== "" && loadinfo.destination !== "") {
      setTripInfo({});
      const request = {
        origin: loadinfo.origin,
        destination: loadinfo.destination,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
      };
      directionsService.current.route(request, (result: any, status: any) => {
        if (status === google.maps.DirectionsStatus.OK) {
          const distance = result?.routes[0]?.legs[0]?.distance?.text;
          const duration = result?.routes[0]?.legs[0]?.duration?.text;
          setLoadInfo({ ...loadinfo, distance, duration });
          tollGet(result?.routes[0].overview_polyline, duration, distance).then((tollCost) => {
            fuelPriceGet(result?.routes[0]?.legs[0]?.start_location, duration, distance, tollCost);
          });

          directionsRenderer.current.setMap(mapRef.current);
          directionsRenderer.current.setDirections(result);
        }
      });
    }
    // eslint-disable-next-line
  }, [loadinfo.origin, loadinfo.destination]);

  return (
    <div>
      <div id="map" style={mapContainerStyle}></div>
    </div>
  );
};

export default Map;
