import { CalendarIcon, TruckIcon, ClockIcon, ArrowUturnRightIcon } from "@heroicons/react/24/outline";
import { BuildingLibraryIcon, CurrencyDollarIcon } from "@heroicons/react/24/solid";

const LoadDetail = ({ loadInfo }: any) => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <CalendarIcon className="w-5 h-5 text-gray-500"></CalendarIcon>
            <div className="text-sm font-sm text-gray-500">Pickup Date</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.pickupDate || "-"}</div>
        </div>
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <TruckIcon className="w-5 h-5 text-gray-500"></TruckIcon>
            <div className="text-sm font-sm text-gray-500">Truck</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.truckType || "-"}</div>
        </div>
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <BuildingLibraryIcon className="w-5 h-5 text-gray-500"></BuildingLibraryIcon>
            <div className="text-sm font-sm text-gray-500">Company</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.company || "-"}</div>
        </div>
        <div className="border-1.5 border-gray w-full rounded p-2 flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <CurrencyDollarIcon className="w-5 h-5 text-gray-500"></CurrencyDollarIcon>
            <div className="text-sm font-sm text-gray-500">Rate</div>
          </div>
          <div className="text-sm font-bold px-1">{loadInfo.rate || "-"}</div>
        </div>
      </div>
    </div>
  );
};

export default LoadDetail;
